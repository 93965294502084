<template>
  <div class="onboarding">
    <div class="tw-container tw-mx-auto tw-flex tw-flex-col-reverse md:tw-flex-row md:tw-pt-24">
      <onboarding-form
        :documents="uploadDocuments"
        class="md:tw-mr-16 md:tw-w-3/6 lg:tw-w-2/6"
        data-test="form"
      />
      <div
        class="onboarding__content tw-text-white md:tw-w-3/6 lg:tw-w-4/6 tw-mb-12 md:tw-mb-0"
        data-test="content"
      >
        <div
          class="lg:tw-w-1/2 tw-mt-3"
        >
          <div class="onboarding__content__header">
            <h1
              data-test="title"
              class="onboarding__title tw-mb-4 tw-text-4xl tw-font-light"
              v-text="$t('onboarding.titles.main')"
            />
          </div>
          <p
            data-test="complete-paragraph"
            v-text="$t('onboarding.paragraphs.documents.complete')"
          />
          <i18n
            path="onboarding.paragraphs.documents.difficulties"
            tag="p"
            data-test="difficulties-paragraph"
          >
            <template #email>
              <UiLink
                :href="`mailto:${email}`"
                data-test="email"
                class="!tw-text-white hover:!tw-text-white"
                v-text="email"
              />
            </template>
          </i18n>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import store from '@/store'
  import chronoinfos from '@/../config/chronoinfos'

  import OnboardingForm from './components/OnboardingForm/index.vue'

  /**
   * @module view - Onboarding
   */
  export default defineComponent({
    name: 'Onboarding',
    metaInfo () {
      return {
        title: this.$t('onboarding.titles.main')
      }
    },
    components: {
      OnboardingForm
    },
    async beforeRouteEnter (to, from, next) {
      if (!store.getters['auth/isPreRegistered']) {
        return next({
          name: 'Auth'
        })
      }

      const res = await store.dispatch('onboarding/retrieveDocuments')
      if (res && res.data && res.data.documents) {
        const hasUploadedEverything = res.data.documents.every(document => document.uploaded)
        if (hasUploadedEverything) {
          return next({
            name: 'OnboardingConfirmation'
          })
        }
      }

      store.dispatch('setAppReady', true)
      next()
    },
    data () {
      return {
        documents: [],
        email: chronoinfos.emails.contact
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      ...mapGetters('onboarding', [
        'getDocuments'
      ]),
      uploadDocuments () {
        const hints = {
          kbis: this.$t('onboarding.labels.hints.kbis'),
          iban: this.$t('onboarding.labels.hints.iban')
        }

        return Array.from(this.getDocuments)
          .map(document => ({
            ...document,
            hint: Object.keys(hints).includes(document.key) ? hints[document.key] : null
          }))
      }
    }
  })
</script>

<style lang="scss" scoped>

  .onboarding {
    padding: 32px 0 64px 0;
    overflow-y: scroll !important;
    background-color: $secondary-lighten;
  }

</style>
